
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

 

@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$khaleej13-primary: mat.define-palette(mat.$indigo-palette);
$khaleej13-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$khaleej13-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$khaleej13-theme: mat.define-light-theme((
  color: (
    primary: $khaleej13-primary,
    accent: $khaleej13-accent,
    warn: $khaleej13-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($khaleej13-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */


@import "~bootstrap/scss/functions";

@import  "~bootstrap/scss/variables" ;
  



$carousel-control-color:             $primary !important;
$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/root";



@import "~bootstrap/scss/bootstrap";
/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@font-face {
  font-family: Nirmala UI;
  src: url(/assets/fonts/Nirmala.ttf);
}

:root{
  --space: 2rem;
  --space-xs: calc(var(--space) / 3);
  --space-sm: calc(var(--space) / 2);
  --space-md: calc(var(--space) * 2);
  /*COLORS SCHEHMA*/
  --fourth-color: #5191fa;
  --first-color:#ff681a;
  --secondary:#828086;
  --secound-color:#828086;
  --third-color:#303030;
  --graylight:#bcb7b7;
  --footer-color:#000000;
  --white-color:#FFFFFF;
  --fifth-color:#ccc;
  --sixth-color:#bcb7b7;
  --seventh-color:#014264;
  --eigth-color:#fba41c;
  --ninth-color:#314354;

  
  /*FONTS SCHEHMA*/
  --first:20px;
  --secound:1.563rem;
  --third:24px; 
  --fourth:18px;
  --fifth:16px;
  --sixth:12px;
  --seventh:14px;
  --eighth:17px;
  --ninth:15px;
  --tenth:21px;
  --eleventh:19px;
  --twelevth:17px;  
  --thirdth:13px;
  --fourteenth:25px;

  --font-family-en:Roboto;

  /*........................................................................*/
  /*New Design Scheme ...*/
  /*FONST SIZES*/
  --high : 28px;
  --hight2:24px;
   --hight3:60px;
   
  --medium: 20px;
  --medium2:18px;
  --medium4:16px;
  --medium3:15px;

  --low:14px;
  --low3:13px;
  --low2:12px;
  --low4:11px;

  /*COLORS*/
  --first-c:    #282969;
  --secound-c:  #FFFFFF;
  --third-c:    #FF215C;
  --fourth-c:   #262626;
  --fifth-c:    #707070; 
  --sixth-c:    #3D454E;
  --seventh-c:  #858585;
  --eigth-c:    #C7BFBF;
  --ninth-c:    #D7FFBF;
  --tenth-c:    #555656;
  --eleventh-c: #AAABAB;
  --tweleveth-c:#3D454E;
  --thirteenth-c:#7E7E7E;
  --thirdth-c:   #932258;
  --border-fill:#E8E8E8;
  --red-c:      #F06748;
  --green-c:    #4DB70F;
  --strongBlue: #131445;
  --footerGrey: #9FA4B0;
  --flightResultGrey:#f8f8f8;
  --bigblue:#12154A;
  --border-gray:#dfdfdf;
  --gray-c:#3E3E3E;
  --grayh-c:#434A54;
  --gray-dash:#ADA8A8;
  --lightbag:#dedede;
  --graylightPas:#E3E3E3;

  /*FONT FAMILIES*/
  --first-en  : 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  --secound-en: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  --third-en  :Lato;
  --fourth-en : Nirmala UI;
 
  /*........................................................................*/
}
html, body { height: 100%; }
body { margin: 0;   overflow-x: hidden;}
body{
  background: url('./assets/img/torest/Background.webp') 0 0 no-repeat fixed;   
}
.tripIdeasTabs .mat-tab-header{
border-bottom: 1px solid #FF215C;
}

.tripIdeasTabs .mat-tab-header .mat-tab-labels .mat-tab-label{
color: black;
opacity: 1;
min-width: 100px;
border-start-end-radius: 10px;
border-start-start-radius: 10px;
background-color: #e5e5e5;
margin-left: 1%;
margin-right: 1%;
}

.tripIdeasTabs .mat-tab-header .mat-tab-label.mat-tab-label-active{
background-color: #FF215C;
border-bottom-color:#FF215C;
border-start-end-radius: 10px;
border-start-start-radius: 10px;
min-width: 100px;
margin-left: 0%;
margin-right: 0%;
}

.tripIdeasTabs .mat-tab-header .mat-tab-label.mat-tab-label-active .mat-tab-label-content{
background-color: #FF215C !important;
color: black !important;
font-size: 16px;
font-family: 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.tripIdeasTabs .mat-tab-header .mat-ink-bar{
background-color: #FF215C;
}

.tripIdeasTabs .mat-tab-header .mat-tab-labels .mat-tab-label .mat-tab-label-content{
color:#262626 ;
font-size: 16px;
font-family: 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
cursor: pointer;
width: 24px !important;
height: 24px !important;
top: -10px !important;
background-color: white !important;
z-index: 3;
border-radius: 16px;
border: 2px solid #282969 !important;
}
.custom-slider .ngx-slider .ngx-slider-pointer::after {
display: none;
}

.custom-slider .ngx-slider .ngx-slider-selection{
background: #282969;
}

.filterCheckbox .mat-checkbox-inner-container{
height: 18px;
}
.filterCheckbox .mat-checkbox-frame{
border-radius: 5px !important;
}
.modal-dialog {
max-width: 48rem !important;
}

.filterCheckbox .mat-checkbox-layout .mat-checkbox-label{
line-height: 20px;
}

.customFilterCheckBoxMargin .mat-checkbox-layout .mat-checkbox-label{
margin-top: 10%;
}
.carousel-control-prev, .carousel-control-next {
  color:#b3b1b1 ;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
.phonenumber {
  width: 100%;
  border: 1px solid #e8e8e8;
  height: 41.5px;
  border-radius: 4px;
  padding-left: 65px !important;
}
[dir=rtl] .float-end{
  float: left !important;
}
[dir=rtl] .iconMenu {
  float: left !important;
}

.iti__country-list {
  direction: ltr;
}

@media (min-width:1800px) {
  body{
    background: url('./assets/img/torest/Background.webp') 0 0 no-repeat ;  
    background-size: 100%; 
  }
}

